define("discourse/plugins/discourse-translator/discourse/services/translator", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TranslatorService extends _service.default {
    async translatePost(post) {
      const response = await (0, _ajax.ajax)("/translator/translate", {
        type: "POST",
        data: {
          post_id: post.id
        }
      });
      post.detectedLang = response.detected_lang;
      post.translatedText = response.translation;
      post.translatedTitle = response.title_translation;
    }
    clearPostTranslation(post) {
      post.detectedLang = null;
      post.translatedText = null;
      post.translatedTitle = null;
    }
  }
  _exports.default = TranslatorService;
});